import axios                                                   from 'axios';
import {CANDY_MACHINE, DEBUG, NFT_CONTRACT, rounds, scNetwork} from './constants';

let restHosts: string[] = []

if (scNetwork === 'testnet') {
	restHosts = [
		'https://pisco-lcd.terra.dev',
		'https://terra-testnet-api.polkachu.com'
	]
} else if (scNetwork === 'mainnet') {
	restHosts = [
		// "https://fcd.terrav2.ccvalidators.com:443",
		// "https://lcd-terra.wildsage.io:443",
		// "https://terra-mainnet-lcd.autostake.com:443",
		"https://lcd-terra2.whispernode.com:443",
		'https://phoenix-lcd.terra.dev',
		'https://terra2-api.dalnim.finance'
	]
}

let restHostIndex = Math.floor(Math.random() * restHosts.length);
let hostUsageCount:any = {};
restHosts.forEach((host: string) => {
	hostUsageCount[host] = 0;
})

const getNextMainnetRestHost = (): string => {
	const host    = restHosts[restHostIndex];
	restHostIndex = (restHostIndex + 1) % restHosts.length;
	hostUsageCount[host]++;
	if(DEBUG) {
		console.log(`Host: ${host}, Usage Count: ${hostUsageCount[host]}`);
	}
	return host;
}

export const getNftCountForAddress = async (address: string): Promise<number> => {
	let queryMsg = ownerHasNftsQueryMsgBase64(address);
	let response = await axios.get(`${getNextMainnetRestHost()}/cosmwasm/wasm/v1/contract/${NFT_CONTRACT}/smart/${queryMsg}`);
	return response.data.data.tokens.length;
}

export const getRemainingMintForAddress = async (address: string, round: number): Promise<number> => {
	let queryMsg = whitelistAddressQueryMsgBase64(address, round);
	let response = await axios.get(`${getNextMainnetRestHost()}/cosmwasm/wasm/v1/contract/${CANDY_MACHINE}/smart/${queryMsg}`);

	return response.data.data !== null ? response.data.data.count : -1;
}

export const queryWhitelistAddress = async (address: string, round: number): Promise<any> => {
	let queryMsg = whitelistAddressQueryMsgBase64(address, round);
	let response = await axios.get(`${getNextMainnetRestHost()}/cosmwasm/wasm/v1/contract/${CANDY_MACHINE}/smart/${queryMsg}`);
	return response.data.data;
}

export const queryWhitelistSingle = async (address: string): Promise<any> => {
	let queryMsg = whitelistSingleQueryMsgBase64(address);
	let response = await axios.get(`${getNextMainnetRestHost()}/cosmwasm/wasm/v1/contract/${CANDY_MACHINE}/smart/${queryMsg}`);
	return response.data.data;
}

export const queryConfig = async (): Promise<any> => {
	let queryMsg = configQueryMsgBase64();
	let response = await axios.get(`${getNextMainnetRestHost()}/cosmwasm/wasm/v1/contract/${CANDY_MACHINE}/smart/${queryMsg}`);
	return response.data.data;
}

export const getMintAvailableForAddress = async (address: string, round: number): Promise<number> => {
	if (!address.startsWith("terra1")) return 0;
	let roundOneRemaining = await getRemainingMintForAddress(address, 1);
	let isRoundOneWL      = roundOneRemaining >= 0;
	if (round === 1) {
		if (isRoundOneWL) {
			return roundOneRemaining;
		} else {
			return rounds[1].limit;
		}
	}

	let roundTwoRemaining = await getRemainingMintForAddress(address, 2);
	let isRoundTwoWL      = roundTwoRemaining >= 0;
	if (round === 2) {
		if (isRoundTwoWL) {
			return roundTwoRemaining;
		} else {
			return rounds[2].limit;
		}
	}

	if (round === 3) {
		let nftCount = await getNftCountForAddress(address);
		if (DEBUG) {
			console.log(`nftCount: ${nftCount}`);
		}
		if (!isRoundOneWL && !isRoundTwoWL) {
			if (nftCount === 0) {
				if (DEBUG) {
					console.log(`nftCount: ${nftCount}`);
					console.log(`rounds[3].limit: ${rounds[3].limit}`);
					console.log(`rounds.totalMint: ${rounds.totalMint}`);
				}
				return rounds[3].limit;
			} else {
				if (DEBUG) {
					console.log(`nftCount: ${nftCount}`);
					console.log(`rounds[3].limit: ${rounds[3].limit}`);
					console.log(`rounds.totalMint: ${rounds.totalMint}`);
				}
				return rounds[3].limit - nftCount;
			}
		} else if (isRoundOneWL && isRoundTwoWL) {
			if (DEBUG) {
				console.log(`roundOneRemaining: ${roundOneRemaining}`);
				console.log(`roundTwoRemaining: ${roundTwoRemaining}`);
				console.log(`nftCount: ${nftCount}`);
				console.log(`rounds[3].limit: ${rounds[3].limit}`);
				console.log(`rounds.totalMint: ${rounds.totalMint}`);
			}
			return (rounds.totalMint - nftCount - roundOneRemaining - roundTwoRemaining)
		} else if (isRoundOneWL && !isRoundTwoWL) {
			if (DEBUG) {
				console.log(`roundOneRemaining: ${roundOneRemaining}`);
				console.log(`nftCount: ${nftCount}`);
				console.log(`rounds[3].limit: ${rounds[3].limit}`);
				console.log(`rounds.totalMint: ${rounds.totalMint - 2}`);
			}
			return ((rounds.totalMint - 2) - nftCount - roundOneRemaining)
		} else if (!isRoundOneWL && isRoundTwoWL) {
			if (DEBUG) {
				console.log(`roundOneRemaining: ${roundOneRemaining}`);
				console.log(`roundTwoRemaining: ${roundTwoRemaining}`);
				console.log(`nftCount: ${nftCount}`);
				console.log(`rounds[3].limit: ${rounds[3].limit}`);
				console.log(`rounds.totalMint: ${rounds.totalMint - 2}`);
			}
			return ((rounds.totalMint - 2) - nftCount - roundTwoRemaining)
		}
		return 0;
	}
	return 0;
}


const ownerHasNftsQueryMsgBase64 = (address: string, start_after = null, limit = 30) => {
	let msg;
	if (start_after === null) {
		msg = `{"tokens":{"owner":"${address}","limit":${limit}}}`
	} else {
		msg = `{"tokens":{"owner":"${address}","limit":${limit}, "start_after":"${start_after}"}}`
	}
	return Buffer.from(msg).toString('base64')
}

const whitelistAddressQueryMsgBase64 = (address: string, round: number) => {
	let msg = `{"whitelist_address":{"round":${round},"addr":"${address}"}}`
	// console.log(msg);
	return Buffer.from(msg).toString('base64')
}
const whitelistSingleQueryMsgBase64  = (address: string) => {
	let msg = `{"whitelist_single":{"addr":"${address}"}}`
	// console.log(msg);
	return Buffer.from(msg).toString('base64')
}

const configQueryMsgBase64 = () => {
	let msg = `{"config":{}}`
	return Buffer.from(msg).toString('base64')
}
