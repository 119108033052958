import styled from "styled-components";

export const CardOuter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const MintCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  border: 6px solid #93202d;
  margin-bottom: 20px;
  border-radius: 30px;
  padding: 10px 20px 10px 10px;

  @media (min-width: 450px) {
    padding: 20px 20px 20px 10px;
    max-width: 400px;
  }
  @media (min-width: 1199px) {
    padding: 20px 20px 20px 10px;
    max-width: 400px;
  }
`;

export const ImageWrapper = styled.div`
  transition: 0.3s;
`;

export const Image = styled.img`
  max-width: 100%;
  border-radius: 0px;
  height: auto;
  margin: 5px;
`;

export const InputWidget = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--hc);
`;

export const PriceWidget = styled.div`
  font-size: 32px;
  margin-bottom: 10px;
  text-align: center;
`;

export const InputNumberButton = styled.button`
  text-decoration: none;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: var(--hc);
  &:disabled {
    opacity: 0.1;
  }
`;

export const InputNumber = styled.div`
  font-size: 32px;
  color: var(--hc);
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 5px 10px;
  margin: auto;
  margin-top: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 32px;
  letter-spacing: 0.5px;
  font-family: var(--hff);
  border: none;
  position: relative;
  color: black;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  background-color: #fcee21;
  outline: none;
  //border: 2px solid rgba(0, 0, 0, 1);
  border-radius: 10px;
`;

export const MiddleSection = styled.div<{ connected: boolean }>`
  margin: 20px 0px ${(props) => (props.connected ? "5px" : "30px")};
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NftLeft = styled.div`
  font-size: 32px;
  margin-top: 0px;
`;

export const SoldOut = styled.div`
  padding: 10px 10px;
  flex: 1;
  border-radius: 10px;
  margin: 20px 0px;
  background: #fcee21;
  //border: 1px solid rgba(255, 255, 255, 0.5);
  color: black;
  text-transform: uppercase;
  font-size: 32px;
`;

export const FooterWrapper = styled.div``;

export const FooterText = styled.p`
  margin: 20px 0px 5px;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
`;

export const Link = styled.a`
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  color: black;
  &:hover {
    color: yellow;
  }
`;

export const PhaseTitle = styled.a`
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fcee21;
  font-weight: 500;
  position: relative;
  text-align: center;
`;
