import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --mc1: #de3633;
    --mc2: #7000ff;
    --hc: #fcee21;
    --bc: #9ba0b8;
    --hff: 'vt323', sans-serif;
    --bff: 'vt323', sans-serif;
  }
  @font-face {
      font-family: "vt323";
      src: url("vt323.ttf");
  }
  body {
    margin: 0;
    padding: 0;
    font-family: var(--bff);
    color: var(--hc);
    //background: url('pixeLions_BG.webp') no-repeat 0 50%;
    background: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,.8)), url("pixeLions_BG2.webp");
    background-size: cover;
    background-attachment: fixed;
    background-position:center;
    //background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(18,15,47,1) 50%, rgba(0,0,0,1) 100%);
    //linear-gradient(90deg, yellow 0%, black 50%, yellow 100%); //linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(18,15,47,1) 50%, rgba(0,0,0,1) 100%); //#f5f410 yellow
/*
    :before {
      content: '';
      position: fixed;
      width: 270px;
      height: 270px;
      top: 27.5%;
      left: 23.9%;
      background-color: var(--mc1);
      border-radius: 100%;
      -webkit-filter: blur(150px);
      -o-filter: blur(150px);
      filter: blur(150px);
      z-index: -1;
    }

    :after {
      content: '';
      position: fixed;
      width: 270px;
      height: 270px;
      top: 42.8%;
      right: 25%;
      background-color: var(--mc2);
      border-radius: 100%;
      -webkit-filter: blur(150px);
      -o-filter: blur(150px);
      filter: blur(150px);
      z-index: -1;
    }
    */
  }
  p {
    margin-bottom: 32px;
    line-height: 24px;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: var(--hff);
    font-weight: 300;
    color: var(--hc);
    text-transform: uppercase;
  }

  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .logo-text{
    max-width:400px;
    width:90%;
  }
  .container-logo{
    text-align: center;
    width: 100%;
  }
  .strong{
    font-weight: 900;
    margin-left:10px;
  }
`;
