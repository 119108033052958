export const CANDY_MACHINE: string = `terra1d2gjv4f4elh0hsgjl8rzymg857k4ys6af0lutz6gw6vwf2kny6dsdewwdw`
export const NFT_CONTRACT: string  = 'terra17z7fpaa8kah698xn5tarrcucvualdy4wsztkfc404g3garucpu6qmxp50g'
export const DEBUG: boolean        = process.env.NODE_ENV !== 'production';
export const chains: any           = {
	"mainnet": {
		"chainId"            : "phoenix-1",
		"chainName"          : "Terra",
		"chainSymbolImageUrl": "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/phoenix/chain.png",
		"rpc"                : "https://terra2-rpc.dalnim.finance/",
		"rest"               : "https://terra2-api.dalnim.finance/",
		"bip44"              : {
			"coinType": 330
		},
		"alternativeBIP44s"  : [
			{
				"coinType": 118
			}
		],
		"bech32Config"       : {
			"bech32PrefixAccAddr" : "terra",
			"bech32PrefixAccPub"  : "terrapub",
			"bech32PrefixValAddr" : "terravaloper",
			"bech32PrefixValPub"  : "terravaloperpub",
			"bech32PrefixConsAddr": "terravalcons",
			"bech32PrefixConsPub" : "terravalconspub"
		},
		"currencies"         : [
			{
				"coinDenom"       : "LUNA",
				"coinMinimalDenom": "uluna",
				"coinDecimals"    : 6,
				"coinGeckoId"     : "terra-luna-2",
				"coinImageUrl"    : "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/phoenix/uluna.png"
			}
		],
		"feeCurrencies"      : [
			{
				"coinDenom"       : "LUNA",
				"coinMinimalDenom": "uluna",
				"coinDecimals"    : 6,
				"coinGeckoId"     : "terra-luna-2",
				"coinImageUrl"    : "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/phoenix/uluna.png",
				"gasPriceStep"    : {
					"low"    : 0.015,
					"average": 0.025,
					"high"   : 0.04
				}
			}
		],
		"stakeCurrency"      : {
			"coinDenom"       : "LUNA",
			"coinMinimalDenom": "uluna",
			"coinDecimals"    : 6,
			"coinGeckoId"     : "terra-luna-2",
			"coinImageUrl"    : "https://raw.githubusercontent.com/chainapsis/keplr-chain-registry/main/images/phoenix/uluna.png"
		},
		"walletUrlForStaking": "https://wallet.keplr.app/chains/terra",
		"features"           : ["cosmwasm"]
	},
	"testnet": {
		rpc          : "https://pisco-rpc.dalnim.finance",
		rest         : "https://pisco-lcd.terra.dev",
		chainId      : "pisco-1",
		chainName    : "Terra Testnet",
		stakeCurrency: {
			coinDenom       : "LUNA",
			coinMinimalDenom: "uluna",
			coinDecimals    : 6,
			coinGeckoId     : "terra-luna-2"
		},
		bip44        : {
			coinType: 330
		},
		bech32Config : {
			bech32PrefixAccAddr : "terra",
			bech32PrefixAccPub  : "terrapub",
			bech32PrefixValAddr : "terravaloper",
			bech32PrefixValPub  : "terravaloperpub",
			bech32PrefixConsAddr: "terravalcons",
			bech32PrefixConsPub : "terravalconspub"
		},
		currencies   : [
			{
				coinDenom       : "LUNA",
				coinMinimalDenom: "uluna",
				coinDecimals    : 6,
				coinGeckoId     : "terra-luna-2"
			},
			{
				coinDenom       : "axlUSDC",
				coinMinimalDenom: "ibc/B3504E092456BA618CC28AC671A71FB08C6CA0FD0BE7C8A5B5A3E2DD933CC9E4",
				coinDecimals    : 6,
				coinGeckoId     : "axlUSDC"
			},
			{
				coinDenom       : "axlWETH",
				coinMinimalDenom: "ibc/BC8A77AFBD872FDC32A348D3FB10CC09277C266CFE52081DE341C7EC6752E674",
				coinDecimals    : 18,
				coinGeckoId     : "axlWETH"
			},
			{
				coinDenom       : "axlDAI",
				coinMinimalDenom: "ibc/E46EF5449878F6B81219163F211E7329CC0729AA99DA8A589A865F82F754ADE8",
				coinDecimals    : 18,
				coinGeckoId     : "axlDAI"
			},
			{
				coinDenom       : "axlUSDT",
				coinMinimalDenom: "ibc/CBF67A2BCF6CAE343FDF251E510C8E18C361FC02B23430C121116E0811835DEF",
				coinDecimals    : 6,
				coinGeckoId     : "axlUSDT"
			},
			{
				coinDenom       : "axlBUSD",
				coinMinimalDenom: "ibc/FDDF98401F29AC63212C1742F9EC86D3AA1E1BE94BF9EB2F72B990C490303F42",
				coinDecimals    : 18,
				coinGeckoId     : "axlBUSD"
			},
			{
				coinDenom       : "axlDOT",
				coinMinimalDenom: "ibc/5B01781626783C316A22ACBB55ECA8C6C57AA92C1565C69128C192FA36680AFB",
				coinDecimals    : 10,
				coinGeckoId     : "axlDOT"
			},
			{
				coinDenom       : "axlWAVAX",
				coinMinimalDenom: "ibc/F992067A054C819B42D2DAB57F5CCE347D38352EB90453E59D566BFE64F1614B",
				coinDecimals    : 18,
				coinGeckoId     : "axlWAVAX"
			},
			{
				coinDenom       : "axlWGLMR",
				coinMinimalDenom: "ibc/D54CE4CD2927F744CDCA844DD0E1A5DF88762274C55CD9AAB13E504A29BE8933",
				coinDecimals    : 18,
				coinGeckoId     : "axlWGLMR"
			},
			{
				coinDenom       : "axlWMATIC",
				coinMinimalDenom: "ibc/14E4FD1AB72DE9BF1D6725CBA18373C406CB9A7DA17955299F3F4DC5C6131A4E",
				coinDecimals    : 18,
				coinGeckoId     : "axlWMATIC"
			},
			{
				coinDenom       : "axlWBTC",
				coinMinimalDenom: "ibc/05D299885B07905B6886F554B39346EA6761246076A1120B1950049B92B922DD",
				coinDecimals    : 8,
				coinGeckoId     : "axlWBTC"
			},
			{
				coinDenom       : "axlWBNB",
				coinMinimalDenom: "ibc/1319C6B38CA613C89D78C2D1461B305038B1085F6855E8CD276FE3F7C9600B4C",
				coinDecimals    : 18,
				coinGeckoId     : "axlWBNB"
			},
			{
				coinDenom       : "axlWFTM",
				coinMinimalDenom: "ibc/19E687E77D1AE3CADBB3DE487277AFEC0E340A84334D6ED3F216EF25A7075746",
				coinDecimals    : 18,
				coinGeckoId     : "axlWFTM"
			},
			{
				coinDenom       : "axlRAI",
				coinMinimalDenom: "ibc/88C5B3E62FD8ECC8825107D86C4AC8CDECDC7B44135DD757D1D83E27203B6C9D",
				coinDecimals    : 18,
				coinGeckoId     : "axlRAI"
			},
			{
				coinDenom       : "axlLINK",
				coinMinimalDenom: "ibc/1FD62537E1FBE67DF7574E0234112B4FE417B20AADC2F574026CB664EA9492C7",
				coinDecimals    : 18,
				coinGeckoId     : "axlLINK"
			},
			{
				coinDenom       : "axlAAVE",
				coinMinimalDenom: "ibc/2E9CD07D7A6572A4CDAABBF0FBB89F69A9A362818132221182654819E277220A",
				coinDecimals    : 18,
				coinGeckoId     : "axlAAVE"
			},
			{
				coinDenom       : "axlFRAX",
				coinMinimalDenom: "ibc/2E435CEEEBA18CCB2719E0182BC5D142A364D6CCE9957DE6E1AC4D62127D2913",
				coinDecimals    : 18,
				coinGeckoId     : "axlFRAX"
			},
			{
				coinDenom       : "axlMKR",
				coinMinimalDenom: "ibc/EF21BC562B7EB4D22BBF467D011E3118B7E3AD6D095000A672A9FCECA5ABCF15",
				coinDecimals    : 18,
				coinGeckoId     : "axlMKR"
			},
			{
				coinDenom       : "axlAPE",
				coinMinimalDenom: "ibc/5D36185CDE12E13DA9832315D2A35F07D52D952E3A242496C9A634808D86DC07",
				coinDecimals    : 18,
				coinGeckoId     : "axlAPE"
			},
			{
				coinDenom       : "axlUNI",
				coinMinimalDenom: "ibc/6EFF21F9E65C9101370C38AA53049E4D1FF2B206A7C350B45F0ED3660E57AC75",
				coinDecimals    : 18,
				coinGeckoId     : "axlUNI"
			},
			{
				coinDenom       : "axlSHIB",
				coinMinimalDenom: "ibc/62CC85ED0F0C43C453B1A059BF5A147498550C2A7ADDEE6324A0B4E9E371C9C7",
				coinDecimals    : 18,
				coinGeckoId     : "axlSHIB"
			},
			{
				coinDenom       : "axlAXS",
				coinMinimalDenom: "ibc/2C6CDA86FA7F45A39C053584422C5B76C287DB9E3746CAF623C3EC7717D4EC5B",
				coinDecimals    : 18,
				coinGeckoId     : "axlAXS"
			},
			{
				coinDenom       : "axlXCN",
				coinMinimalDenom: "ibc/C22747737204871040AE896E7CA7791CF541AC269BF1D521870891E67AC83DA4",
				coinDecimals    : 18,
				coinGeckoId     : "axlXCN"
			}
		],
		feeCurrencies: [
			{
				coinDenom       : "LUNA",
				coinMinimalDenom: "uluna",
				coinDecimals    : 6,
				coinGeckoId     : "terra-luna-2",
				gasPriceStep    : {
					low    : 0.01,
					average: 0.025,
					high   : 0.03
				}
			}
		],
		features     : ["stargate", "ibc-transfer", "no-legacy-stdTx"],
		beta         : true
	}
}

export type CandyMachineRound = {
	round: number;
	future_start_time: number;
}

export type CandyMachineConfig = {
	owner: string;
	token_addr: string;
	mint_asset: {
		info: {
			native_token: {
				denom: string;
			}
		},
		amount: string;
	},
	round: number;
	protocol_fee: string;
	creator: string;
	collector: string;
	total_token_count: string;
	enable_whitelist: boolean;
	total_supply: string;
	is_open: boolean;
	rounds: CandyMachineRound[];
}

export enum ConnectedWithExtension {
	None    = 'None',
	Keplr   = 'Keplr',
	Station = 'Station'
}

export const scNetwork: string = 'mainnet';

export const rounds: any = {
	"1"            : {"title": "WL Round 1", "startTime": 1686348001, "endTime": 1686391201, "limit": 2},
	"2"            : {"title": "WL Round 2", "startTime": 1686393001, "endTime": 1686436201, "limit": 2},
	"3"            : {"title": "Public Round", "startTime": 1686438001, "endTime": 1687042800, "limit": 10},
	"totalMint"    : 14,
	"mintStartTime": 1686438000
}
