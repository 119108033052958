import styled from "styled-components";

export const TimeWidgetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 5px;
  margin: 0px 0px 15px;

  @media (min-width: 768px) {
    gap: 15px;
  }

  @media (min-width: 1199px) {
    gap: 30px;
  }
`;

export const TimeCircle = styled.div`
  width: 94px;
  height: 94px;
  padding: 0px;
  border: 1px double black;
  background: #a1122c;
  /*background-image: linear-gradient(
      0deg,
      rgb(0, 0, 0) 0%,
      #220e27 92.63%,
      rgb(0, 0, 0) 131.74%
    ),
    linear-gradient(0deg, rgb(0, 0, 0), rgb(255, 255, 255));*/
  border-radius: 100%;
  background-origin: border-box;
  background-clip: content-box, border-box;
  color: white;
  letter-spacing: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //gap: 5px;

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

export const Time = styled.span`
  font-size: 50px;
  font-weight: 900;
  text-align: center;
`;

export const TimeLabel = styled.span`
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
`;
