import {
	Img,
	Navbar,
	Link
} from "./style";

export default function TimeWidget() {

	return (
		<Navbar>
			<a href="https://www.backbonelabs.io/"><Img src="https://www.backbonelabs.io/img/logo.png"/></a>
			<Link href="https://necropolis.backbonelabs.io/" title="Check your mint" aria-label="Check your mint"
			      target="_blank">NECROPOLIS</Link>
			<Link href="https://necropolis.backbonelabs.io/my-nft" title="Check your mint" aria-label="Check your mint"
			      target="_blank">My NFTs</Link>
		</Navbar>
	);
}
