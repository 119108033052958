import {MsgExecuteContract}  from "@terra-money/terra.js";
import {
	ConnectType,
	useConnectedWallet,
	useWallet,
	WalletStatus
}                            from "@terra-money/wallet-provider";
import TimeWidget            from "components/TimeWidget/TimeWidget";
import {useEffect, useState} from "react";
import {
	toast
}                            from 'react-toastify';
import {
	Button,
	CardOuter,
	FooterText,
	FooterWrapper,
	Image,
	ImageWrapper,
	InputNumber,
	InputNumberButton,
	InputWidget,
	Link,
	MiddleSection,
	MintCardWrapper,
	NftLeft,
	PhaseTitle,
	PriceWidget,
	SoldOut
}                            from "./style";
import {
	CANDY_MACHINE,
	CandyMachineConfig,
	chains,
	ConnectedWithExtension,
	DEBUG,
	rounds,
	scNetwork
}                            from '../../utils/constants';
import {
	getMintAvailableForAddress,
	queryConfig,
	queryWhitelistAddress,
	queryWhitelistSingle
}                            from '../../utils/helpers';

import {Window as KeplrWindow}             from "@keplr-wallet/types";
import {GasPrice, SigningStargateClient}   from '@cosmjs/stargate';
import {MsgExecuteContractEncodeObject}    from '@cosmjs/cosmwasm-stargate';
import {Decimal}                           from '@cosmjs/math';
import {Registry}                          from '@cosmjs/proto-signing';
import {MsgExecuteContract as MsgContract} from 'cosmjs-types/cosmwasm/wasm/v1/tx';

declare global {
	interface Window extends KeplrWindow {
	}
}

const KEPLR = window.keplr;

export default function MintCard() {
	const contractAddress: string = CANDY_MACHINE;

	const carouselImages = 10;

	const [refreshIntervalMillis, setRefreshIntervalMillis]   = useState<number>(4 * 1_000);
	const [carouselIntervalMillis, setCarouselIntervalMillis] = useState<number>(2 * 1_000);

	const {wallets, status, connect, disconnect, post} = useWallet();
	const connectedWallet                              = useConnectedWallet();

	const [updatedContract, setUpdatedContract]           = useState<boolean>(false);
	const [mintSelection, setMintSelection]               = useState<number>(1);
	const [maxNftMint, setMaxNftMint]                     = useState<number>(5);
	const [timeLeft, setTimeLeft]                         = useState<number | undefined>(rounds.mintStartTime);
	const [currentPhaseIndex, setCurrentPhaseIndex]       = useState(0);
	const [carouselIndex, setCarouselIndex]               = useState(0);
	const [nftPrice, setNftPrice]                         = useState("0");
	const [nftsLeft, setNftsLeft]                         = useState<string>("50");
	const [maxMintPerUser, setMaxMintPerUser]             = useState<string>("1");
	const [canMint, setCanMint]                           = useState(false);
	const [isWhitelisted, setIsWhitelisted]               = useState(false);
	const [isAddressWhitelisted, setIsAddressWhitelisted] = useState(false);
	const [roundTitle, setRoundTitle]                     = useState<string>("");
	const [hasKeplrWallet, setHasKeplrWallet]             = useState(false);
	const [connectedWith, setConnectedWith]               = useState<ConnectedWithExtension>(ConnectedWithExtension.None);
	const [connectedAddress, setConnectedAddress]         = useState<string>("");
	const [isQuerying, setIsQuerying]                     = useState(false);
	const [isMinting, setIsMinting]                       = useState(false);
	const [isMintingComplete, setIsMintingComplete]       = useState(false);
	const [canPoll, setCanPoll]                           = useState(true);
	const [isMintOpen, setIsMintOpen]                     = useState(true);

	window.onload = async () => {
		if (!window.getOfflineSigner || !KEPLR) {
			console.error("Please install keplr extension");
			setHasKeplrWallet(false);
		} else {
			if (KEPLR?.experimentalSuggestChain) {
				try {
					await KEPLR?.experimentalSuggestChain(chains[scNetwork]);
				} catch {
					console.error("Failed to suggest the chain");
				}
			} else {
				console.error("Please use the recent version of keplr extension");
			}
			KEPLR?.enable(chains[scNetwork].chainId);
			setHasKeplrWallet(true);
		}
	};

	useEffect(() => {
		let timerId: any;

		const resetTimer = () => {
			clearTimeout(timerId);
			timerId = setTimeout(() => {
				setCanPoll(false);
			}, 3 * 60 * 1000); // Stop polling after 5 minutes of inactivity
		};

		resetTimer();

		return () => {
			clearTimeout(timerId);
		};
	}, [canPoll]);


	useEffect(() => {
		const timeRefresh = setInterval(() => {
			if (connectedWith === ConnectedWithExtension.None || timeLeft === undefined || currentPhaseIndex !== 1) {
				return;
			}
			setTimeLeft((value) => Math.max(0, value! - 1));
		}, 1000);
		return () => {
			clearInterval(timeRefresh);
		};
	}, [timeLeft, currentPhaseIndex, connectedWith]);

	useEffect(() => {
		let timeout: any;
		if (isMinting) {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				setIsMinting(false);
				setMaxNftMint(1);
				setMintSelection(1)
			}, 10000);
		}
		return () => {
			clearTimeout(timeout)
		};
	}, [isMinting, isMintingComplete])

	useEffect(() => {
		// check localstorage for connectedWith
		const storedValue = localStorage.getItem("connectedWith");

		let retrievedConnectedWith: ConnectedWithExtension | null = null;

		if (storedValue) {
			retrievedConnectedWith = storedValue as ConnectedWithExtension;
		}

		if (retrievedConnectedWith !== null) {
			if (retrievedConnectedWith === ConnectedWithExtension.Keplr) {
				handleConnectKeplr().then();
			} else if (retrievedConnectedWith === ConnectedWithExtension.Station) {
				setIsQuerying(true)
				setTimeout(() => {
					handleConnectWallet();
					setIsQuerying(false)
				}, 2000);
			}
		}

		setIsQuerying(true);
		if (canPoll) {
			queryConfig()
				.then((config: CandyMachineConfig) => {
					if (config.is_open) {
						setTimeLeft(rounds[config.round].endTime);
						setCurrentPhaseIndex(config.round);
						setRoundTitle(rounds[config.round].title);
						setIsQuerying(false);
					}
				})
		}
		const carouselRefresh = setInterval(() => {
			setCarouselIndex((value) => (value + 1 >= carouselImages ? 0 : value + 1));
		}, carouselIntervalMillis);
		return () => {
			clearInterval(carouselRefresh);
		};
	}, []);

	useEffect(() => {
		if (connectedAddress === "") {
			setConnectedWith(ConnectedWithExtension.None)
		}

		localStorage.setItem("connectedAddress", connectedAddress);
	}, [connectedAddress])

	useEffect(() => {
		if (parseInt(maxMintPerUser) > 0) {
			let nftsLeft = (parseInt(maxMintPerUser) - mintSelection).toString();
			setNftsLeft(nftsLeft);
			if (connectedAddress.startsWith("terra1")) {
				setIsQuerying(true);
				if (canPoll) {
					queryWhitelistAddress(connectedAddress, currentPhaseIndex)
						.then((response: any) => {
							if (response !== null) {
								// console.log(`whitelist_address response:`, response)
								setIsAddressWhitelisted(true);
								setMaxNftMint(response.count)
							}
							setIsQuerying(false);
						})
				}
			}
		}
	}, [maxMintPerUser, connectedAddress, canPoll])

	useEffect(() => {

		if (!canPoll) return;

		if (connectedWith === ConnectedWithExtension.Keplr) {
			if (DEBUG) console.log(`connectedWith: ${connectedWith}`)
			if (window.getOfflineSigner !== undefined) {
				const offlineSigner = window.getOfflineSigner(chains[scNetwork].chainId);
				offlineSigner.getAccounts()
				             .then((accounts) => {
					             setConnectedAddress(accounts[0].address)
				             });
			}
		} else if (connectedWith === ConnectedWithExtension.Station) {
			if (DEBUG) {
				console.log(`||| connectedWith: ${connectedWith} station`)
				console.log(`||| wallets:`, wallets)
			}
		}

		const contractRefresh = setInterval(getContractInfo, refreshIntervalMillis);
		getContractInfo().then();
		return () => {
			clearInterval(contractRefresh);
		};
	}, [connectedWith, status, connectedAddress, isWhitelisted, isAddressWhitelisted, canPoll]);

	useEffect(() => {
		localStorage.setItem("connectedWith", connectedWith);
	}, [connectedWith])

	useEffect(() => {
		if (connectedWith === ConnectedWithExtension.Station && status == WalletStatus.WALLET_CONNECTED) {
			setConnectedAddress(wallets[0].terraAddress);
		}
	}, [status])

	useEffect(() => {
		if (DEBUG) {
			console.log('-------------------------------')
			console.log(`connectedWith: ${connectedWith}`)
			console.log(`connectedAddress: ${connectedAddress}`)
			console.log(`isWhitelisted: ${isWhitelisted}`)
			console.log(`isAddressWhitelisted: ${isAddressWhitelisted}`)
			console.log(`maxNftMint: ${maxNftMint}`)
			console.log('-------------------------------')
		}

		if (maxNftMint > 0) {
			if (isWhitelisted && isAddressWhitelisted) {
				setCanMint(true);
			} else if (!isWhitelisted && connectedWith !== ConnectedWithExtension.None) {
				setCanMint(true);
			} else {
				setCanMint(false);
			}
		} else {
			setCanMint(false);
		}
	}, [maxNftMint, connectedAddress])

	const checkWhiteListLimit = () => {
		if (isWhitelisted && connectedAddress.startsWith("terra1")) {
			if (canPoll) {
				setIsQuerying(true);
				queryWhitelistSingle(connectedAddress)
					.then((response: any) => {
						if (response !== null) {
							setIsAddressWhitelisted(true);
							if (response.count === 0) {
								setCanMint(false);
								setMaxMintPerUser("0");
							} else  {
								console.log(`-*-****************************response`,response)
								setCanMint(true);
								setMaxMintPerUser(response.count.toString())
							}
						} else {
							setCanMint(false);
							setMaxMintPerUser("0");
							setIsAddressWhitelisted(false);
						}
						setIsQuerying(false)
					})
			}
		}
	}

	useEffect(() => {
		if (!canPoll) return;

		checkWhiteListLimit()
	}, [isWhitelisted, connectedAddress, canPoll])

	const getContractInfo = async () => {
		if (connectedWith === ConnectedWithExtension.None) {
			return;
		}

		if (!canPoll) return;

		queryConfig()
			.then((config: CandyMachineConfig) => {
				if (!config.is_open) {
					if(DEBUG) {
						console.log(`mint is not open`)
						console.log(`mint will open at ${rounds.mintStartTime}`)
					}
					setTimeLeft(rounds.mintStartTime);
					setIsMintOpen(config.is_open);
					setRefreshIntervalMillis(60 * 1000);
					return;
				} else {
					if(DEBUG) {
						console.log(`mint is open`)
						console.log(`mint will open at ${rounds[1].endTime}`)
					}
					setIsMintOpen(config.is_open)
				}

				if (DEBUG) {
					console.log(`=====================`)
					console.log(`|| config:`, config)
					console.log(`|| isWhitelisted:`, isWhitelisted)
					console.log(`|| isAddressWhitelisted:`, isAddressWhitelisted)
					console.log(`|| maxMintPerUser`, maxMintPerUser)
					console.log(`|| maxNftMint`, maxNftMint)
					console.log(`|| round`, config.round);
					console.log(`|| connectedAddress: ${connectedAddress}`)
					console.log(`|| canMint: ${canMint}`)
					console.log(`=====================`)
				}

				getMintAvailableForAddress(connectedAddress, config.round)
					.then((availableForMint: number) => {
						// console.log(`availableForMint:`, availableForMint)
						setMaxMintPerUser(availableForMint.toString())
						setMaxNftMint(availableForMint)
						if (DEBUG) {
							console.log(`=====================`)
							console.log(`|| availableForMint:`, availableForMint)
							console.log(`=====================`)
						}
						if (availableForMint <= 0) {
							setCanMint(false);
							setRefreshIntervalMillis(60 * 1000);
						} else if(availableForMint > 0 && isAddressWhitelisted){
							setCanMint(true);
							setRefreshIntervalMillis(4 * 1000);
						}
					})
				setIsWhitelisted(config.enable_whitelist);
				setCurrentPhaseIndex(config.round);
				setNftPrice(config.mint_asset.amount);
				setRoundTitle(rounds[config.round].title);
				setNftsLeft(config.total_token_count);
				setUpdatedContract(true);
				setIsQuerying(false);
			});
	};

	const mint = async (event: React.MouseEvent<HTMLButtonElement>) => {
		try {
			const button    = event.currentTarget;
			button.disabled = true;
			if (connectedWith === ConnectedWithExtension.Station) {
				let msgsArray: MsgExecuteContract[] = [];
				for (let i = 0; i < mintSelection; i++) {
					msgsArray.push(
						new MsgExecuteContract(connectedAddress,
						                       contractAddress,
						                       {mint: {}},
						                       {uluna: nftPrice}
						)
					)
				}

				const tx = post(
					{
						msgs: msgsArray
					}, contractAddress
				).finally(() => {
					setIsMinting(true);
					getContractInfo();
					button.disabled = false;
				});

				await toast.promise(tx,
				                    {
					                    pending: 'Transaction is pending.',
					                    success: {
						                    render({data}) {
							                    const msg = data!.success
								                    ? `Successfully minted ${mintSelection} NFTs.`
								                    : `Failed to mint. Please try again.`;
							                    return msg;
						                    }
					                    },
					                    error  : {
						                    render({data}) {
							                    const msg = "Transaction has failed. Please try again.";
							                    console.error(data);
							                    return msg;
						                    }
					                    }
				                    }
				);
			} else if (connectedWith === ConnectedWithExtension.Keplr && window.getOfflineSigner !== undefined) {
				const offlineSigner = window.getOfflineSigner(chains[scNetwork].chainId);
				const accounts      = await offlineSigner.getAccounts();
				let gas             = Decimal.fromUserInput("0.025", 6)
				// @ts-ignore
				let gasPrice        = new GasPrice(gas, 'uluna');
				let r               = new Registry();
				r.register("/cosmwasm.wasm.v1.MsgExecuteContract", MsgContract);
				const client = await SigningStargateClient.connectWithSigner(
					chains[scNetwork].rpc,
					offlineSigner,
					{
						gasPrice,
						registry: r
					}
				);

				let msgs: MsgExecuteContractEncodeObject[] = [];
				for (let i = 0; i < mintSelection; i++) {
					msgs.push(
						{
							typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
							value  : {
								contract: contractAddress,
								sender  : accounts[0].address,
								msg     : new TextEncoder().encode(JSON.stringify(
									{
										mint: {}
									})
								),
								funds   : [
									{
										denom : "uluna",
										amount: nftPrice
									}
								]
							}
						}
					)
				}

				let txRes = client.signAndBroadcast(accounts[0].address, msgs, "auto", "")
				                  .finally(() => {
					                  setIsMinting(true);
					                  getContractInfo();
					                  button.disabled = false;
				                  });

				await toast
					.promise(txRes,
					         {
						         pending: 'Transaction is pending.',
						         success: {
							         render({data}) {
								         const msg = data?.transactionHash !== ""
									         ? `Successfully minted ${mintSelection} NFTs.`
									         : `Failed to mint. Please try again.`;
								         return msg;
							         }
						         },
						         error  : {
							         render({data}) {
								         const msg = "Transaction has failed. Please try again.";
								         console.error(data);
								         return msg;
							         }
						         }
					         }
					);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const middleSection = () => {
		if (connectedWith !== ConnectedWithExtension.None && updatedContract) {
			return (
				<NftLeft>{nftsLeft} left {/*} {totalNftForSale} */}</NftLeft>
			);
		}
		return <></>;
	};

	const changeMintSelection = (increment: boolean) => {
		if (increment) {
			setMintSelection((value) => Math.min(Math.min(maxNftMint, parseInt(nftsLeft)), value + 1));
		} else {
			setMintSelection((value) => Math.max(0, value - 1));
		}
	}

	function isMobile() {
		return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	}

	const handleConnectWallet = () => {
		if (connectedWith === ConnectedWithExtension.None) {
			if (isMobile()) {
				connect(ConnectType.WALLETCONNECT);
				setConnectedWith(ConnectedWithExtension.Station)
			} else {
				connect(ConnectType.EXTENSION);
				setConnectedWith(ConnectedWithExtension.Station)
			}
		}
	}

	const handleDisconnectStation = () => {
		if (connectedWith === ConnectedWithExtension.Station) {
			setConnectedWith(ConnectedWithExtension.None)
			setConnectedAddress("")
			disconnect();
		}
	}

	const handleConnectKeplr = async () => {
		if (window.getOfflineSigner !== undefined) {
			const offlineSigner = window.getOfflineSigner(chains[scNetwork].chainId);
			const accounts      = await offlineSigner.getAccounts();
			setConnectedWith(ConnectedWithExtension.Keplr)
		}
	}

	const handleDisconnectKeplr = () => {
		setConnectedWith(ConnectedWithExtension.None);
		setConnectedAddress("")
	}

	const footer = () => {

		if (connectedWith !== ConnectedWithExtension.None && !connectedAddress.startsWith("terra1")) {
			return (
				<>
					<p>Didn't detect address properly please refresh and try again</p>
					<Button onClick={() => window.location.reload()}>Refresh</Button>
					{connectedWith === ConnectedWithExtension.Station &&
					 <Button onClick={handleDisconnectStation}>Disconnect Station</Button>
					}

					{connectedWith === ConnectedWithExtension.Keplr &&
					 <Button onClick={handleDisconnectKeplr}>Disconnect Keplr</Button>
					}
				</>
			)
		}

		if (!canPoll) {
			return (
				<>
					Idle timeout. Please refresh the page.
					<Button onClick={() => window.location.reload()}>Refresh</Button>
				</>
			)
		}

		if (isMinting && !isMintingComplete) {
			return (
				<>
					Processing...
				</>
			)
		}

		if ((connectedWith === ConnectedWithExtension.None || !updatedContract) && isMintOpen)
			return (
				<>
					{!isMobile() ?
						<>
							<Button onClick={handleConnectWallet}><Image src="ICON_STATION.webp"/>Connect <span
								className="strong">Station</span></Button>
							<Button onClick={handleConnectKeplr}><Image src="ICON_KEPLR.webp"/>Connect <span
								className="strong">Keplr</span></Button>
						</>
						:
						<>
							<Button onClick={handleConnectWallet}>Connect Station</Button>
						</>
					}
				</>
			);

		if(!isMintOpen)
			return <SoldOut>Mint Not Open</SoldOut>

		if (parseInt(nftsLeft) === 0)
			return <SoldOut>Sold out</SoldOut>;

		return canMint ?
			(
				<>
					<PriceWidget>{`${((parseFloat(nftPrice) / 10 ** 6) * mintSelection).toFixed(0)} LUNA`}</PriceWidget>
					<InputWidget>
						<InputNumberButton onClick={() => changeMintSelection(false)}
						                   disabled={mintSelection === 1}>{"<"}</InputNumberButton>
						<InputNumber>{mintSelection}</InputNumber>
						<InputNumberButton onClick={() => changeMintSelection(true)}
						                   disabled={mintSelection === maxNftMint || mintSelection === parseInt(nftsLeft)}>{">"}</InputNumberButton>
					</InputWidget>
					<Button onClick={mint}>Mint</Button>
					{connectedWith === ConnectedWithExtension.Station &&
					 <Button onClick={handleDisconnectStation}>Disconnect Station</Button>
					}

					{connectedWith === ConnectedWithExtension.Keplr &&
					 <Button onClick={handleDisconnectKeplr}>Disconnect Keplr</Button>
					}
					<FooterText>
						<Link href="https://necropolis.backbonelabs.io/my-nft" title="Check your mint" aria-label="Check your mint"
						      target="_blank">Check on Necropolis</Link>
					</FooterText>
				</>
			)
			:
			<>
				<Button onClick={() => window.location.reload()}>Refresh</Button>
				{isWhitelisted ? (
					isAddressWhitelisted && parseInt(maxMintPerUser) <= 0 ? (
						<>
							<FooterText>Max Mint Reached</FooterText>
							{connectedWith === ConnectedWithExtension.Station &&
							 <Button onClick={handleDisconnectStation}>Disconnect Station</Button>
							}
							{connectedWith === ConnectedWithExtension.Keplr &&
							 <Button onClick={handleDisconnectKeplr}>Disconnect Keplr</Button>
							}
						</>
					) : (
						<>
							<FooterText>Not Whitelisted</FooterText>
							{connectedWith === ConnectedWithExtension.Station &&
							 <Button onClick={handleDisconnectStation}>Disconnect Station</Button>
							}
							{connectedWith === ConnectedWithExtension.Keplr &&
							 <Button onClick={handleDisconnectKeplr}>Disconnect Keplr</Button>
							}
						</>
					)
				) : (
					parseInt(maxMintPerUser) <= 0 ? (
							<>
								<FooterText>Minted Max For Address</FooterText>
								{connectedWith === ConnectedWithExtension.Station &&
								 <Button onClick={handleDisconnectStation}>Disconnect Station</Button>
								}

								{connectedWith === ConnectedWithExtension.Keplr &&
								 <Button onClick={handleDisconnectKeplr}>Disconnect Keplr</Button>
								}
							</>
						)
						:
						(
							<>
							</>
						)
				)}
			</>
	}

	return (
		<CardOuter>
			<MintCardWrapper>
				<PhaseTitle>
					{roundTitle}
				</PhaseTitle>
				<MiddleSection connected={connectedWith !== ConnectedWithExtension.None && updatedContract}>
					<ImageWrapper>
						<Image src={`/carousel/${carouselIndex}.png`} alt="carousel image"/>
					</ImageWrapper>
					{middleSection()}
				</MiddleSection>
				<FooterWrapper>
					{footer()}
				</FooterWrapper>
			</MintCardWrapper>
			{!isMintOpen &&
			 <PhaseTitle> Mint will open in </PhaseTitle>}
			<TimeWidget time={timeLeft}/>
		</CardOuter>
	);
}
