import { getChainOptions, WalletProvider } from '@terra-money/wallet-provider';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MintCard from './components/MintCard/MintCard';
import PageTitle from './components/PageTitle/PageTitle';
import NavBar from './components/Navbar/Navbar';
import { GlobalStyle } from './styles/style';

function App() {
  return (
    <main>
      <div className=''></div>
      <GlobalStyle />
      <NavBar />
      {/*<PageTitle isMain={true}>Pixelions Mint (Testnet)</PageTitle>*/}
      <div className='container-logo'>
        <img src='pixeLions_logo_text.webp' className='logo-text' />
      </div>
      <MintCard />
      <ToastContainer />
    </main>
  );
}

getChainOptions().then((chainOptions) => {
  ReactDOM.render(
    <WalletProvider {...chainOptions}>
      <App />
    </WalletProvider>,
    document.getElementById('root'),
  );
});
