import { useEffect, useState } from "react";
import { Time, TimeCircle, TimeLabel, TimeWidgetWrapper } from "./style";

export default function TimeWidget(props: { time: number | undefined }) {
	const [hours, setHours] = useState<number | null>(null);
	const [mins, setMins] = useState<number | null>(null);
	const [secs, setSecs] = useState<number | null>(null);

	useEffect(() => {
		let intervalId: any;

		if (props.time !== undefined) {
			// Calculate the time remaining from the current time to the target time
			const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
			let timeRemaining = props.time - currentTime;
			if (timeRemaining < 0) {
				timeRemaining = 0; // Ensure the countdown doesn't go negative
			}

			const newHours = Math.floor(timeRemaining / 3600);
			timeRemaining %= 3600;
			const newMins = Math.floor(timeRemaining / 60);
			const newSecs = Math.floor(timeRemaining % 60);

			setHours(newHours >= 0 ? newHours : 0);
			setMins(newMins >= 0 ? newMins : 0);
			setSecs(newSecs >= 0 ? newSecs : 0);
		}

		intervalId = setInterval(() => {
			setSecs((prevSecs) => {
				if (prevSecs === null) return null;

				const newSecs = prevSecs - 1;

				if (newSecs < 0) {
					setMins((prevMins) => {
						if (prevMins === null) return null;

						const newMins = prevMins - 1;

						if (newMins < 0) {
							setHours((prevHours) => {
								if (prevHours === null) return null;
								return prevHours - 1 >= 0 ? prevHours - 1 : 0;
							});
							return 59;
						}

						return newMins;
					});

					return 59;
				}

				return newSecs >= 0 ? newSecs : 0;
			});
		}, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, [props.time]);

	return (
		<TimeWidgetWrapper>
			<TimeCircle>
				<Time>{hours ?? "-"}</Time>
				<TimeLabel>Hour</TimeLabel>
			</TimeCircle>
			<TimeCircle>
				<Time>{mins ?? "-"}</Time>
				<TimeLabel>Min</TimeLabel>
			</TimeCircle>
			<TimeCircle>
				<Time>{secs ?? "-"}</Time>
				<TimeLabel>Sec</TimeLabel>
			</TimeCircle>
		</TimeWidgetWrapper>
	);
}
