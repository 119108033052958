import styled from "styled-components";

export const Navbar = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px;
  align-items: center;
  font-family: "Poppins", sans-serif;
`;

export const Button = styled.button`
  display: block;
  width: fit-content;
  padding: 2px 60px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.5px;
  line-height: 50px;
  border: none;
  position: relative;
  color: var(--hc);
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  outline: none;
`;

export const Link = styled.a`
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  color: white;
  &:hover {
    color: light blue;
  }
  border: 1px solid;
  padding: 7px;
  border-radius: 7px;
  margin-left: 20px;
  margin-right: 20px;
  height: 20px;
`;

export const Img = styled.img`
  margin-left: 20px;
  margin-right: 20px;
  width: 45px;
`;
